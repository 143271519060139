import { DisclosurePopupData } from "types/DisclosurePopup.interface";

export interface Link {
  title: string;
  href: string;
  open_in_new_tab?: boolean;
  disclosure_popup?: DisclosurePopupData[];
}

export enum FooterVariant {
  Default = "default",
  Simple = "simple",
}

export interface CSFooterData {
  design_variant: FooterVariant;
  background_color: string;
  logo: {
    url: string;
    title: string;
    description: string;
  };
  logo_link: {
    url: string;
    ga_label: string;
    open_in_new_tab: boolean,
    text: string;
  };
  sub_logo: {
    url: string;
    title: string;
    description: string;
  };
  sub_copyright_links: {
    url: string;
    text: string;
    ga_label: string;
    open_in_new_tab: boolean;
  }[];
  country_list: {
    pre_text: string;
    country_link_list: {
      country_link: {
        link: Link;
      };
    };
  }[];
  copyright_text: string;
  call_information: {
    icon_new: string;
    link: Link;
  };
  calling_hours_text: string;
  calling_hours_text_mobile: string;
  social_media_links: {
    social_media: {
      icon_new: string;
      link: Link;
      disclosure_popup?: DisclosurePopupData[];
    };
  }[];
  sections: {
    major_grouping: {
      major_grouping_link: Link;
      sub_links: {
        sub_link: {
          link: Link;
          link_type?: string;
          main_link?: boolean;
          disclosure_popup?: DisclosurePopupData[];
        };
      }[];
    };
  }[];
  other_sections: {
    snippet: string;
    image: {
      url: string;
      title: string;
    };
    image_2: {
      url: string;
      title: string;
    };
  };
  other_links: {
    other_link: {
      link: Link;
    };
  }[];
  sub_footer_links: {
    link: {
      link: Link;
    };
  }[];
  general_enquiry_reference: {
    next_button_hint: string;
    form_type: string;
    next_button_text: string;
    start_button_text: string;
    type_description: string;
    generic_required_error_message: string;
    api_error_message_form: string;
    global_error_validation_msg: string;
    max_length_error_message: string;
    min_length_error_message: string;
    sending: string;
    submission_endpoint: string;
    send_email_endpoint: string;
    authentication_key: string;
    modular_blocks: any[];
  }[];
}
