import { colorsUtils, useResponsive } from "@d2c-ui-components-react";
import {
  DisclosurePopup as NextGenDisclosurePopup,
  Footer as NextGenFooter,
  fwdColors,
} from "@fwd-dep/nextgen-ui-lib";
import { useMediaQuery } from "@material-ui/core";
import { EnquiryContext, EnquiryContextType } from "context/EnquiryContext";
import {
  ModalPageNotFoundContext,
  ModalPageNotFoundContextType,
} from "context/ModalPageNotFoundContext";
import I18nContext from "i18n/context/LanguageContext";
import get from "lodash/get";
import { useRouter } from "next/router";
import { useCallback, useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { IFooter } from "types/Footer.interface";
import { IGtm } from "types/Gtm.interface";
import { gtmUltils, replaceContentStackURL, setWindowOpen } from "utils";
import { gaEventAction } from "utils/gtm";
import { resolveAbsoluteUrl } from "utils/route";
import { transformLineBreak } from "utils/string";
import { CSFooterData, FooterVariant } from "./footer.type";
import { getNextGenFooterProps } from "./getNextGenFooterProps";

const StyledFooter = styled.div<{
  variant?: FooterVariant;
  darkMode?: boolean;
}>`
  ${(props) =>
    props.variant === FooterVariant.Simple &&
    css`
      .bg-color-orangeLighter {
        background-color: #fff !important;
      }
    `}
  && {
    ul li::after {
      color: ${colorsUtils.grey2};
    }
    ul {
      li {
        .label-color-orangeLighter {
          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .startIcon {
    mask-image: unset !important;
  }

  ${(props) =>
    props.darkMode &&
    css`
      &&& {
        .text-color-darkGreen,
        .label-color-darkGreen {
          span {
            color: ${colorsUtils.white};
          }
          span:hover {
            color: ${colorsUtils.darkGreen50};
          }
        }

        .MuiSelect-root {
          color: ${colorsUtils.white};
          &:hover {
            color: ${colorsUtils.darkGreen50};
          }
        }

        .MuiLink-root {
          &:hover {
            span {
              color: ${colorsUtils.darkGreen50};
            }
            background-color: unset;

            .branding-icon {
              background-color: ${colorsUtils.darkGreen50};
              svg path {
                fill: ${colorsUtils.darkGreen};
              }
            }
          }
        }

        .branding-icon {
          background-color: ${colorsUtils.white};
          svg path {
            fill: ${colorsUtils.darkGreen};
          }
        }

        svg path {
          fill: ${colorsUtils.white};
        }

        svg:hover {
          path {
            fill: ${colorsUtils.darkGreen50};
          }
        }
      }
    `}
`;

interface FooterProps {
  footerData: IFooter;
  variant?: FooterVariant;
}

const GTM_COMPONENT_NAME = "Footer";
const DARK_MODE_BG = "darkgreen";

export const Footer = (props: FooterProps) => {
  const footerData = props.footerData;
  const footerVariant = props.variant;
  const router = useRouter();
  const modalPageNotFoundContext = useContext(
    ModalPageNotFoundContext
  ) as ModalPageNotFoundContextType;

  const [disclosurePopups, setDisclosurePopups] = useState({
    availablePopupList: [],
    activePopupKey: null,
    eligiblePopupList: [],
    isRoutedDirectly: false,
  });

  const i18nContext = useContext(I18nContext);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:959px)");

  const footerProps = useMemo(() => {
    if (isMobile || isTablet) {
      const newFooterData = { ...footerData };
      if (newFooterData.sections) {
        const newSections: typeof newFooterData.sections = [];
        for (let i = 0; i < newFooterData.sections.length; i++) {
          const currentSection = { ...newFooterData.sections[i] };
          const newSection = { ...newFooterData.sections[i] };
          newSection.subLinks = [];
          newSections.push(newSection);
          if (currentSection.subLinks) {
            for (let j = 0; j < currentSection.subLinks.length; j++) {
              if (currentSection.subLinks[j].main_link) {
                newSections.push({
                  title: currentSection.subLinks[j].title,
                  open_in_new_tab: currentSection.subLinks[j].open_in_new_tab,
                  url: currentSection.subLinks[j].url,
                  subLinks: [],
                });
              } else {
                newSection.subLinks.push(currentSection.subLinks[j]);
              }
            }
          }
        }
        newFooterData.sections = newSections;
      }
      return getNextGenFooterProps(newFooterData, i18nContext);
    }
    return getNextGenFooterProps(footerData, i18nContext);
  }, [isMobile, isTablet, footerData]);

  const changeLocale = (languageCode: string) => {
    const { asPath } = router;
    const url = asPath.replace(i18nContext.language.languageCode, languageCode);
    let urlWithSufficSlash = url.split("?")[0].replace(/\/?$/, "/");
    var anchor = window.location.hash.substring(1);
    if (!!anchor) {
      urlWithSufficSlash = urlWithSufficSlash
        .replace(anchor, "")
        .replace("#", "");
      urlWithSufficSlash = urlWithSufficSlash.replace("//", "/");
    }
    if (i18nContext.siteSettings.validUrls.includes(urlWithSufficSlash)) {
      i18nContext.changeLanguage(languageCode);
      setWindowOpen(url, "_self");
    } else {
      modalPageNotFoundContext.showModal();
    }
  };

  const gtmFooterNavigation = useCallback((gtm: IGtm) => {
    gtmUltils.buttonClick({
      buttonTitle: gtm.buttonTitle,
      destinationUrl: resolveAbsoluteUrl(gtm.destinationUrl, i18nContext),
      componentName: GTM_COMPONENT_NAME,
    });
    gtmUltils.navigation({
      event_action: gaEventAction.button_click,
      button_title: gtm.buttonTitle,
      destination_url: resolveAbsoluteUrl(gtm.destinationUrl, i18nContext),
      component_name: GTM_COMPONENT_NAME,
      product_affiliation:
        i18nContext.componentConfigs?.gaPageData?.product_affiliation || "",
    });
  }, []);

  const setDisclosurePopupList = useCallback((payload: any) => {
    const {
      isRoutedDirectly = true,
      activePopupKey = "",
      eligiblePopupList = "",
    } = payload;

    setDisclosurePopups((prev: any) => ({
      ...prev,
      ...(isRoutedDirectly !== ""
        ? { isRoutedDirectly: isRoutedDirectly }
        : null),
      ...(activePopupKey !== "" ? { activePopupKey: activePopupKey } : null),
      ...(eligiblePopupList !== ""
        ? { eligiblePopupList: eligiblePopupList }
        : null),
    }));
  }, []);

  const setAvailableDisclosurePopupList = useCallback(
    (payload: any) => {
      setDisclosurePopups({
        ...disclosurePopups,
        availablePopupList: payload,
      });
    },
    [disclosurePopups]
  );

  const { updateStatus } = useContext(EnquiryContext) as EnquiryContextType;

  const darkMode = useMemo(
    () => get(footerData, "backgroundColor", "").toLowerCase() === DARK_MODE_BG,
    []
  );

  return (
    <>
      <StyledFooter variant={footerVariant} darkMode={darkMode}>
        <NextGenFooter
          {...footerProps}
          setDisclosurePopupList={setDisclosurePopups}
          updateFormModalState={() => {
            updateStatus({ productName: "", isGeneralEnquiryForm: true });
          }}
          onLanguageChange={changeLocale}
          gtmPushEvent={(event: IGtm) => gtmFooterNavigation(event)}
          variant={footerVariant}
        />
      </StyledFooter>
      <NextGenDisclosurePopup
        {...footerProps.disclosurePopupProps}
        setDisclosurePopupList={setDisclosurePopupList}
        setAvailableDisclosurePopupList={setAvailableDisclosurePopupList}
        availableDisclosurePopupList={disclosurePopups.availablePopupList}
        eligibleDisclosurePopupList={disclosurePopups.eligiblePopupList}
        activeDisclosurePopupKey={disclosurePopups.activePopupKey}
        isDirectRoute={disclosurePopups.isRoutedDirectly}
      />
    </>
  );
};

interface CSFooterProps {
  data: CSFooterData;
}

export const CSFooter = (csData1: any) => {
  const csData = csData1 as CSFooterProps;
  const i18nContext = useContext(I18nContext);
  const isDesktop = useResponsive({ breakpoint: "md" });
  const {
    logo,
    logo_link,
    sub_logo,
    country_list,
    call_information,
    social_media_links = [],
    sections = [],
    other_sections,
    copyright_text,
    other_links = [],
    sub_footer_links = [],
    calling_hours_text = "",
    calling_hours_text_mobile = "",
    background_color,
    design_variant = FooterVariant.Default,
    sub_copyright_links = [],
  } = csData.data || {};

  const fwdBackgroundColor = background_color
    ? Object.keys(fwdColors).find(
        (key: string) =>
          fwdColors[key]?.toUpperCase() === background_color?.toUpperCase()
      ) || "orangeLighter-orange"
    : "orangeLighter-orange";

  const data: IFooter = {
    backgroundColor: fwdBackgroundColor,
    logo: {
      url: replaceContentStackURL(logo?.url || ""),
      title: logo?.description || logo?.title,
    },
    logoLink: {
      url: resolveAbsoluteUrl(logo_link?.url, i18nContext),
      gaLabel: logo_link?.ga_label || "",
      openInNewTab: !!logo_link?.open_in_new_tab,
    },
    subLogo: {
      url: replaceContentStackURL(sub_logo?.url || ""),
      title: sub_logo?.description || sub_logo?.title,
    },
    subCopyrightLinks: sub_copyright_links,
    country: {
      preText: get(country_list, "[0].pre_text"),
      list: get(country_list, "[0].country_link_list", []).map(
        ({ country_link }: any) => ({
          title: country_link?.link?.title,
          url: resolveAbsoluteUrl(country_link?.link?.href, i18nContext),
          code: country_link?.code,
        })
      ),
    },
    callInformation: {
      title: call_information?.link?.title,
      url: resolveAbsoluteUrl(call_information?.link?.href, i18nContext),
      icon: call_information?.icon_new,
    },
    socialMedias: social_media_links.map(
      ({
        social_media: { link, icon_new, disclosure_popup: disclosurePopup },
      }) => ({
        title: link?.title,
        url: resolveAbsoluteUrl(link?.href, i18nContext),
        iconUrl: icon_new?.includes("https")
          ? resolveAbsoluteUrl(icon_new, i18nContext)
          : icon_new,
        disclosurePopup,
      })
    ),
    sections: sections.map(
      ({ major_grouping: { major_grouping_link, sub_links = [] } }) => ({
        title: major_grouping_link?.title,
        url: resolveAbsoluteUrl(major_grouping_link?.href, i18nContext),
        open_in_new_tab: major_grouping_link?.open_in_new_tab,
        disclosurePopup: major_grouping_link?.disclosure_popup,
        subLinks: sub_links.map(
          ({ sub_link: { link, link_type, main_link } }) => {
            return {
              title: link?.title,
              url: resolveAbsoluteUrl(link?.href, i18nContext),
              link_type,
              main_link,
              open_in_new_tab: link?.open_in_new_tab,
              disclosurePopup: link?.disclosure_popup,
            };
          }
        ),
      })
    ),
    otherLinks: other_links.map(({ other_link: { link } }) => ({
      title: link?.title,
      url: resolveAbsoluteUrl(link?.href, i18nContext),
      open_in_new_tab: link?.open_in_new_tab,
      disclosurePopup: link?.disclosure_popup,
    })),
    subFooterLink: sub_footer_links.map(({ link: { link } }) => ({
      title: link?.title,
      url: resolveAbsoluteUrl(link?.href, i18nContext),
      open_in_new_tab: link?.open_in_new_tab,
      disclosurePopup: link?.disclosure_popup,
    })),
    otherSections: {
      image: {
        iconUrl: replaceContentStackURL(other_sections?.image?.url || ""),
        title: other_sections?.image?.title,
      },
      image2: {
        iconUrl: replaceContentStackURL(other_sections?.image_2?.url || ""),
        title: other_sections?.image_2?.title,
      },
      snippet: other_sections?.snippet,
    },
    copyright: transformLineBreak(copyright_text),
    callingHoursText: isDesktop
      ? calling_hours_text
      : calling_hours_text_mobile,
  };

  if (design_variant === FooterVariant.Default && !data.sections?.length) {
    return null;
  }

  return <Footer footerData={data} variant={design_variant} />;
};
