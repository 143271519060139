import { IFooter } from "types/Footer.interface";
import { generateUniqSerial } from "utils/uid";
import { resolveAbsoluteUrl, resolveAbsoluteUrlInParagraph } from "utils/route";
import { replaceContentStackURL, setWindowHref } from "@utils";
import { DisclosurePopupData } from "types/DisclosurePopup.interface";
import isEmpty from "lodash/isEmpty";
import { I18nContextData } from "i18n/context/LanguageContext";
import { transformDisclosurePoppupList } from "services/disclosurePopupService";
interface Link {
  title?: string;
  url?: string;
  code?: string;
  link_type?: string;
  main_link?: boolean;
  iconUrl?: string;
  disclosurePopup?: DisclosurePopupData[];
  disclosure_popup?: DisclosurePopupData[];
  open_in_new_tab?: boolean;
  uid?: string;
}

interface INavigationLink extends Link {
  subLinks?: Link[];
}

enum DisclosurePopupType {
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  OTHER_LINKS = "OTHER_LINKS",
  SUB_FOOTER_LINK = "SUB_FOOTER_LINK",
  SUB_LINK = "SUB_LINK",
  SECTION_LINK = "SECTION_LINK",
}

export function getNextGenFooterProps(
  footerDataInfo: IFooter,
  i18nContext: I18nContextData
) {
  const language = i18nContext.language;
  const defaultLanguage = i18nContext.defaultLanguage;
  const languages = Object.values(i18nContext.supportedLanguages);
  const disclosurePopupData: any = [];
  const countrySelector = (footerDataInfo?.country?.list ?? [])
    .filter((item: Link) => item !== undefined && item !== null)
    .map((item: Link) => ({
      id: generateUniqSerial(),
      name: item?.title,
      displayName: item?.title,
      fields: {
        isDefault: {
          value: item?.code === language?.languageCode,
        },
        buttonIcon: null,
        title: {
          value: item?.title,
        },
        link: {
          value: {
            href: item?.url || "/",
            text: "",
            anchor: "",
            linktype: "internal",
            class: "",
            title: "",
            querystring: "",
            id: generateUniqSerial(),
          },
        },
        listType: {
          id: generateUniqSerial(),
          fieldType: "Droplink",
          fieldName: "listType",
          url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
          fields: {
            value: {
              value: "Generic Link",
            },
            key: {
              value: "generic_link",
            },
          },
        },
        gaLabelName: {
          value: "",
        },
        gaCategoryName: {
          value: "",
        },
      },
    }));

  const generateNavigationChild = (subLinks: Link[], index: number) =>
    subLinks?.map((sublinkItem: Link, i) => {
      if ((sublinkItem?.disclosurePopup || []).length > 0) {
        disclosurePopupData.push({
          ...sublinkItem?.disclosurePopup?.[0],
          id: `${DisclosurePopupType.SUB_LINK}-${index}-${i}`,
          description: resolveAbsoluteUrlInParagraph(
            sublinkItem?.disclosurePopup?.[0]?.description,
            i18nContext
          ),
        });
      }
      const result = {
        id: generateUniqSerial(),
        name: sublinkItem?.title || "",
        displayName: sublinkItem?.title || "",
        fields: {
          isMainLink: !!sublinkItem?.main_link,
          buttonIcon: {
            id: generateUniqSerial(),
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: {
                value: "chevron-right",
              },
              key: {
                value: "chevronRight",
              },
            },
          },
          title: {
            value: sublinkItem?.title || "",
          },
          link: {
            value: {
              href: sublinkItem?.url || "",
              text: "",
              anchor: "",
              class: "",
              title: "",
              querystring: "",
              id: generateUniqSerial(),
              linktype: !isEmpty(sublinkItem?.disclosurePopup)
                ? "modelpopup"
                : sublinkItem?.link_type
                ? sublinkItem?.link_type
                : sublinkItem?.open_in_new_tab
                ? "external"
                : "internal",
              target: sublinkItem?.open_in_new_tab ? "_blank" : "_self",
              disclosurepopupcontent: {
                disclosure: {
                  value: `${DisclosurePopupType.SUB_LINK}-${index}-${i}`,
                },
              },
            },
          },
          listType: {
            id: generateUniqSerial(),
            fieldType: "Droplink",
            fieldName: "listType",
            url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: {
                value: "Generic Link",
              },
              key: {
                value:
                  sublinkItem?.link_type && sublinkItem?.link_type != "form"
                    ? sublinkItem?.link_type
                    : "generic_link",
              },
            },
          },
          gaLabelName: {
            value: sublinkItem?.title || "",
          },
          gaCategoryName: {
            value: "",
          },
        },
      };

      //"linkType=form&queryString=bookAnAgent";
      const title = sublinkItem?.title?.toLowerCase();
      if (title && ["bantuan online", "online enquires"].indexOf(title) >= 0) {
        const url = sublinkItem?.url?.toLowerCase();
        if (url && ["http://", "https://", "/"].indexOf(url) < 0) {
          const urlParams = new URLSearchParams(sublinkItem?.url);
          const linkType = urlParams.get("linkType");
          const queryString = urlParams.get("queryString");

          if (linkType && queryString) {
            result.fields.link.value = {
              href: "",
              text: "",
              anchor: "",
              linktype: linkType,
              class: "",
              title: "",
              target: sublinkItem?.open_in_new_tab ? "_blank" : "_self",
              querystring: queryString,
              id: generateUniqSerial(),
              disclosurepopupcontent: {
                disclosure: {
                  value: "",
                },
              },
            };
          }
        }
      }
      return result;
    });

  const generateSiveNavigationLinks = (item: INavigationLink, i) => {
    if (!!item?.disclosurePopup?.length) {
      disclosurePopupData?.push({
        ...item?.disclosurePopup?.[0],
        id: `${DisclosurePopupType.SECTION_LINK}-${i}`,
        description: resolveAbsoluteUrlInParagraph(
          item?.disclosurePopup?.[0]?.description,
          i18nContext
        ),
      });
    }

    return {
      id: generateUniqSerial(),
      name: item?.title || "",
      displayName: item?.title || "",
      fields: {
        buttonIcon: {
          id: generateUniqSerial(),
          fieldType: generateUniqSerial(),
          fieldName: "buttonIcon",
          url: "/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
          fields: {
            value: {
              value: "chevron-right",
            },
            key: {
              value: "chevronRight",
            },
          },
        },
        title: {
          value: item?.title || "",
        },
        link: {
          value: {
            href: item?.url,
            text: item?.title || "",
            anchor: "",
            linktype: !isEmpty(item?.disclosurePopup)
              ? "modelpopup"
              : item?.open_in_new_tab
              ? "external"
              : "internal",
            disclosurepopupcontent: {
              disclosure: {
                value: `${DisclosurePopupType.SECTION_LINK}-${i}`,
              },
            },
            class: "",
            title: "",
            target: item?.open_in_new_tab ? "_blank" : "_self",
            querystring: "",
            id: generateUniqSerial(),
          },
        },
        listType: {
          id: generateUniqSerial(),
          fieldType: "Droplink",
          fieldName: "listType",
          url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
          fields: {
            value: {
              value: "Generic Link",
            },
            key: {
              value: "generic_link",
            },
          },
        },
        gaLabelName: {
          value: "",
        },
        gaCategoryName: {
          value: "",
        },
      },
      Children: generateNavigationChild(item?.subLinks as Link[], i),
    };
  };

  const generateSocialMedia = footerDataInfo?.socialMedias?.map(
    (item: Link, i: number) => {
      if (item?.disclosurePopup?.length) {
        disclosurePopupData.push({
          ...item?.disclosurePopup[0],
          id: `${DisclosurePopupType.SOCIAL_MEDIA}-${i}`,
          description: resolveAbsoluteUrlInParagraph(
            item?.disclosurePopup[0]?.description,
            i18nContext
          ),
        });
      }
      return {
        id: generateUniqSerial(),
        name: item?.title || "",
        displayName: item?.title || "",
        fields: {
          buttonIcon: {
            id: generateUniqSerial(),
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: {
                value: "chevron-right",
              },
              key: {
                value: "chevronRight",
              },
            },
          },
          title: {
            value: item?.title || "",
          },
          iconUrl: {
            value: item?.iconUrl || "",
          },
          link: {
            value: {
              href: item?.url || "",
              url: item?.url || "",
              anchor: "",
              target: "",
              linktype: !isEmpty(item?.disclosurePopup)
                ? "modelpopup"
                : item?.open_in_new_tab
                ? "external"
                : "internal",
              disclosurepopupcontent: {
                disclosure: {
                  value: `${DisclosurePopupType.SOCIAL_MEDIA}-${i}`,
                },
              },
            },
          },
          listType: {
            id: generateUniqSerial(),
            fieldType: "Droplink",
            fieldName: "listType",
            url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: {
                value: "Generic Link",
              },
              key: {
                value: item?.link_type || "generic_link",
              },
            },
          },
          gaLabelName: {
            value: "",
          },
          gaCategoryName: {
            value: "",
          },
        },
      };
    }
  );

  const generateOtherLinks = footerDataInfo?.otherLinks?.map(
    (item: Link, i) => {
      if (item?.disclosurePopup?.length) {
        disclosurePopupData.push({
          ...item?.disclosurePopup?.[0],
          id: `${DisclosurePopupType.OTHER_LINKS}-${i}`,
          description: resolveAbsoluteUrlInParagraph(
            item?.disclosurePopup?.[0]?.description,
            i18nContext
          ),
        });
      }
      return {
        id: generateUniqSerial(),
        name: item?.title || "",
        displayName: item?.title || "",
        fields: {
          buttonIcon: {
            id: generateUniqSerial(),
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: {
                value: "chevron-right",
              },
              key: {
                value: "chevronRight",
              },
            },
          },
          title: {
            value: item?.title || "",
          },
          link: {
            value: {
              href: item?.url,
              text: "",
              anchor: "",
              class: "",
              title: "",
              querystring: "",
              id: generateUniqSerial(),
              linktype: !isEmpty(item?.disclosurePopup)
                ? "modelpopup"
                : item?.open_in_new_tab
                ? "external"
                : "internal",
              target: item?.open_in_new_tab ? "_blank" : "_self",
              disclosurepopupcontent: {
                disclosure: {
                  value: `${DisclosurePopupType.OTHER_LINKS}-${i}`,
                },
              },
            },
          },
          listType: {
            id: generateUniqSerial(),
            fieldType: "Droplink",
            fieldName: "listType",
            url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: {
                value: "Generic Link",
              },
              key: {
                value: item?.link_type || "generic_link",
              },
            },
          },
          gaLabelName: {
            value: item?.title || "",
          },
          gaCategoryName: {
            value: "",
          },
        },
      };
    }
  );

  const footerQuickLinks = footerDataInfo?.subFooterLink?.map(
    (item: Link, i) => {
      if (item?.disclosurePopup?.length) {
        disclosurePopupData.push({
          ...item?.disclosurePopup?.[0],
          id: `${DisclosurePopupType.SUB_FOOTER_LINK}-${i}`,
          description: resolveAbsoluteUrlInParagraph(
            item?.disclosurePopup?.[0]?.description,
            i18nContext
          ),
        });
      }
      return {
        id: generateUniqSerial(),
        name: item?.title || "",
        displayName: item?.title || "",
        fields: {
          buttonIcon: {
            id: generateUniqSerial(),
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: {
                value: "chevron-right",
              },
              key: {
                value: "chevronRight",
              },
            },
          },
          title: {
            value: item?.title || "",
          },
          link: {
            value: {
              href: item?.url,
              text: "",
              anchor: "",
              linktype: !isEmpty(item?.disclosurePopup)
                ? "modelpopup"
                : item?.open_in_new_tab
                ? "external"
                : "internal",
              disclosurepopupcontent: {
                disclosure: {
                  value: `${DisclosurePopupType.SUB_FOOTER_LINK}-${i}`,
                },
              },
              target: item?.open_in_new_tab ? "_blank" : "_self",
              class: "",
              title: "",
              querystring: "",
              id: generateUniqSerial(),
            },
          },
          listType: {
            id: generateUniqSerial(),
            fieldType: "Droplink",
            fieldName: "listType",
            url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: {
                value: "Generic Link",
              },
              key: {
                value: item?.link_type || "generic_link",
              },
            },
          },
          gaLabelName: {
            value: "",
          },
          gaCategoryName: {
            value: "",
          },
        },
      };
    }
  );

  const navigate = (href: string) => {
    setWindowHref(href);
  };

  const props = {
    navigate: navigate,
    fields: {
      siteNavigationLinks: footerDataInfo?.sections
        ?.concat([{ name: "" } as any])
        ?.map((item: INavigationLink, index: number) => {
          if (item?.subLinks) {
            return {
              id: generateUniqSerial(),
              name: `column-${index.toString()}`,
              displayName: `Column ${index.toString()}`,
              fields: {},
              Children: [
                generateSiveNavigationLinks(item as INavigationLink, index),
              ],
            };
          } else {
            return {
              id: generateUniqSerial(),
              name: `column-${index.toString()}`,
              displayName: `Column ${index.toString()}`,
              fields: {},
              Children: generateOtherLinks,
            };
          }
        }),
      countrySelector,
      socialMediaLinks: generateSocialMedia,
      footerQuickLinks: footerQuickLinks,
      copyrightLabel: {
        value: footerDataInfo?.copyright,
      },
      subCopyrightLinks: footerDataInfo?.subCopyrightLinks.map((item) => {
        return {
          ...item,
          url: resolveAbsoluteUrl(item.url, i18nContext),
        };
      }),
      logo: {
        value: {
          src: replaceContentStackURL(footerDataInfo?.logo?.url || ""),
          alt: footerDataInfo?.logo?.title,
        },
      },
      subLogo: {
        value: {
          src: replaceContentStackURL(footerDataInfo?.subLogo?.url || ""),
          alt: footerDataInfo?.subLogo?.title,
        },
      },
      logoLink: {
        value: {
          href: resolveAbsoluteUrl(footerDataInfo?.logoLink?.url || "/", i18nContext),
          text: footerDataInfo?.logoLink?.text,
          anchor: "",
          linktype: "internal",
          target: footerDataInfo?.logoLink?.openInNewTab ? "_blank" : "",
          class: "",
          title: "",
          querystring: "",
          id: generateUniqSerial(),
          gaEvent: footerDataInfo?.logoLink?.gaLabel
        },
      },
      icon: {
        id: "166421b9-3ce6-4378-ae85-9cc48875088f",
        fieldType: "CustomDroplink",
        fieldName: "icon",
        url: "/sitecore/content/fwd/global/settings/list/linkvariant/call-dual-tone---obsolete",
        fields: {
          value: {
            value: footerDataInfo?.callInformation?.icon,
          },
          key: {
            value: "Call Dual Tone",
          },
        },
      },
      call: {
        value: footerDataInfo?.callInformation?.title,
      },
      enableCallingHoursText: {
        value: !!footerDataInfo?.callingHoursText,
      },
      callingHoursTextBeforeTooltip: {
        value: footerDataInfo?.callingHoursText || "",
      },
      callingHoursTooltip: {
        value: "",
      },
      callingHoursTextAfterTooltip: {
        value: "",
      },
      defaultComplianceAccreditation: {
        id: "44dc59a0-8769-419b-a16a-263d58a9004a",
        fieldType: "Droplink",
        fieldName: "defaultComplianceAccreditation",
        url: "/components/compliance-accreditation/compliance-accreditation-new",
        fields: {
          Description: {
            value: footerDataInfo?.otherSections?.snippet,
          },
          Images: [
            {
              id: "e32c4577-c0e4-49c2-b52e-52c7749f326d",
              fields: {
                link: {
                  value: {},
                },
                image: {
                  value: {
                    src: footerDataInfo?.otherSections?.image?.iconUrl,
                    x1: footerDataInfo?.otherSections?.image?.iconUrl,
                    x2: footerDataInfo?.otherSections?.image?.iconUrl,
                    alt: footerDataInfo?.otherSections?.image?.description,
                  },
                },
                mobileImage: {
                  value: {
                    src: footerDataInfo?.otherSections?.image?.iconUrl,
                    x1: footerDataInfo?.otherSections?.image?.iconUrl,
                    x2: footerDataInfo?.otherSections?.image?.iconUrl,
                    alt: footerDataInfo?.otherSections?.image?.description,
                  },
                },
              },
            },
          ],
          accreditationMode: {
            id: "a3cd3b24-893f-4c01-9e60-aab7f3ab8e9a",
            fieldType: "Droplink",
            fieldName: "accreditationMode",
            url: "/content/accrediationmode/below-footer",
            fields: {
              searchName: {
                value: "belowfooter",
              },
              name: {
                value: "Below Footer",
              },
              articlePillarPage: {
                value: "",
              },
              image: {
                value: {},
              },
              mobileImage: {
                value: {},
              },
              filterBy: null,
            },
          },
        },
      },
      disableComplianceAccreditation: {
        value:
          !footerDataInfo?.otherSections?.snippet &&
          !footerDataInfo?.otherSections?.image?.iconUrl &&
          !footerDataInfo?.otherSections?.image2?.iconUrl,
      },
      gaLabelName: {
        value: "",
      },
      gaCategoryName: {
        value: "",
      },
      enableLanguageToggle: {
        value: true,
      },
      languageList: languages.map((l, i) => ({
        fields: {
          languageName: {
            value: l.displayName,
          },
          defaultLanguage: {
            value:
              languages[languages.length === 1 ? 0 : (i + 1) % 2].languageCode,
          },
          languageCode: {
            value: l.languageCode,
          },
        },
      })),
      languageModalDescription: {
        value: "",
      },
      languageModalLink: {
        value: {},
      },
      languageModalSecondaryLink: {
        value: "",
      },
      languageModalTitle: {
        value: "",
      },
    },
    params: {
      backgroundColor: footerDataInfo?.backgroundColor,
      paddingTop: "Medium",
      paddingBottom: "None",
    },
    sitecoreContext: {
      route: {
        name: "Home",
        displayName: "Home",
        fields: {
          pagedescription: {
            value: "",
          },
          pagetitle: {
            value: "",
          },
          globalMetaTextSetting: null,
          pageType: {
            id: "24e60816-1aa0-4982-8d26-c4ad648393be",
            fieldType: "Droplink",
            fieldName: "pageType",
            url: "/components/pagetype/individual",
            fields: {
              value: {
                value: "Individual",
              },
              key: {
                value: "individual",
              },
            },
          },
          localizedPages: {
            value: {},
          },
          metadataTitle: {
            value: "Insurance Company in Indonesia",
          },
          metadataDescription: {
            value:
              "FWD is an insurance company that offers a range of plans and support including life and health insurance, sharia protection, and investment-linked plans.",
          },
          metadataKeywords: {
            value: "fwd insurance, personal insurance, indonesia insurance",
          },
          disableHTMLCache: {
            value: false,
          },
          accrediationValue: null,
          accreditationMode: null,
          accreditationDescription: {
            value: "",
          },
          disableCompliance: {
            value: false,
          },
          openGraphType: {
            value: "",
          },
          openGraphDescription: {
            value: "",
          },
          openGraphURL: {
            value: {},
          },
          openGraphImage: {
            value: {},
          },
          openGraphTitle: {
            value: "",
          },
          canonicalUrl: {
            value: "",
          },
          isPageSearchable: {
            value: true,
          },
          hideInSitemap: {
            value: false,
          },
          hideLocalizedVersion: {
            value: false,
          },
        },
        databaseName: "web",
        deviceId: "fe5d7fdf-89c0-4d99-9aa3-b5fbd009c9f3",
        itemId: "c3313543-494d-4347-b6ac-743b129ee288",
        itemLanguage: "en",
        itemVersion: 1,
        layoutId: "e60605a5-f4f9-479a-b992-741bce767b1d",
        templateId: "b2dd556f-0f5c-4201-bd59-384db7d20d8c",
        templateName: "Home Page",
        placeholders: {
          "jss-main": [
            {
              uid: "7252d99b-ec55-43a5-bb00-6714906cedc6",
              componentName: "HomePageActionBanner",
              dataSource: "{E9230DF6-0A98-408C-BE91-4DFD506CEA93}",
              fields: {
                navigationTitle: {
                  value: "I want to",
                },
                navigationItems: [
                  {
                    id: "63a44d63-f561-4a45-b079-d494047d64f8",
                    fields: {
                      buttonIcon: null,
                      title: {
                        value: "Buy now",
                      },
                      link: {
                        value: {
                          href: resolveAbsoluteUrl(
                            "/asuransi-kanker/cancer-protection",
                            i18nContext
                          ),
                          linktype: "external",
                          url: resolveAbsoluteUrl(
                            "/asuransi-kanker/cancer-protection",
                            i18nContext
                          ),
                          anchor: "",
                          target: "",
                        },
                      },
                      listType: {
                        id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                        fieldType: "Droplink",
                        fieldName: "listType",
                        url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                        fields: {
                          value: {
                            value: "Generic Link",
                          },
                          key: {
                            value: "generic_link",
                          },
                        },
                      },
                      gaLabelName: {
                        value: "",
                      },
                      gaCategoryName: {
                        value: "",
                      },
                    },
                  },
                  {
                    id: "dcd8101f-0aa5-4fd5-b4f5-ebe8df8d22fc",
                    fields: {
                      buttonIcon: null,
                      title: {
                        value: "Make a claim",
                      },
                      link: {
                        value: {
                          href: "/en/claims/",
                          text: "",
                          anchor: "",
                          linktype: "internal",
                          class: "",
                          title: "",
                          querystring: "",
                          id: "{51A591EB-0797-45A5-92CE-1ADAD6D17EC9}",
                        },
                      },
                      listType: {
                        id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                        fieldType: "Droplink",
                        fieldName: "listType",
                        url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                        fields: {
                          value: {
                            value: "Generic Link",
                          },
                          key: {
                            value: "generic_link",
                          },
                        },
                      },
                      gaLabelName: {
                        value: "",
                      },
                      gaCategoryName: {
                        value: "",
                      },
                    },
                  },
                  {
                    id: "b69a3acd-4c97-42e4-8887-6f5257aca394",
                    fields: {
                      buttonIcon: null,
                      title: {
                        value: "Check investment information",
                      },
                      link: {
                        value: {
                          href: "/en/support/exchange-rate-fund-valuations/",
                          text: "",
                          anchor: "",
                          linktype: "internal",
                          class: "",
                          title: "",
                          querystring: "",
                          id: "{C7B2299E-B772-4A32-9B47-BDC677FCCDA0}",
                        },
                      },
                      listType: {
                        id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                        fieldType: "Droplink",
                        fieldName: "listType",
                        url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                        fields: {
                          value: {
                            value: "Generic Link",
                          },
                          key: {
                            value: "generic_link",
                          },
                        },
                      },
                      gaLabelName: {
                        value: "",
                      },
                      gaCategoryName: {
                        value: "",
                      },
                    },
                  },
                ],
                mobileBrightcoveVideo: null,
                image: {
                  value: {},
                },
                mobileImage: {
                  value: {},
                },
                title: {
                  value: "Insurance the way<br />\nit should be",
                },
                mediaType: {
                  id: "794d29b0-7e58-4605-aeb1-a7c0b34bad14",
                  fieldType: "Droplink",
                  fieldName: "mediaType",
                  url: "/sitecore/content/fwd/global/settings/list/media-type/video",
                  fields: {
                    value: {
                      value: "Video",
                    },
                    key: {
                      value: "Video",
                    },
                  },
                },
                brightcoveVideo: null,
                mobileVideoLink: {
                  value: {
                    src: "/-/media/images/homepage/old_man_with-flamingo_mobile.mp4?rev=4e45b0feb675407b8e2250c013495f47",
                    name: "old_man_with flamingo_mobile",
                    displayName: "old_man_with flamingo_mobile",
                    title: "",
                    keywords: "",
                    description: "",
                    extension: "mp4",
                    mimeType: "video/mp4",
                    size: "2114566",
                  },
                },
                videoLink: {
                  value: {
                    src: "/-/media/images/homepage/old_man_with-flamingo_desktop.mp4?rev=c27e45eb1e294d57b2ee951552ac68dd",
                    name: "old_man_with flamingo_desktop",
                    displayName: "old_man_with flamingo_desktop",
                    title: "",
                    keywords: "",
                    description: "",
                    extension: "mp4",
                    mimeType: "video/mp4",
                    size: "3583211",
                  },
                },
                gaLabelName: {
                  value: "",
                },
                gaCategoryName: {
                  value: "",
                },
              },
              placeholders: {
                "jss-main": [],
              },
            },
          ],
        },
        sitecoreRoute: "",
      },
      itemId: "c3313543-494d-4347-b6ac-743b129ee288",
      pageEditing: false,
      site: {
        name: "fwd-id",
      },
      pageState: "normal",
      language: language?.languageCode,
      languageCode: language?.languageCode,
      defaultLanguage: defaultLanguage?.languageCode,
      siteSettings: {
        productListPage: "/products/",
        splashPage: "",
        globalMetaText: "FWD Malaysia",
        cookieMessage:
          '<p><span>We use cookies to give the best possible web experience. By continuing to use our website we assume you accept how we use cookies and your data. This in keeping with our <a href="~/link.aspx?_id=C9FAF98BDB1C450396E569315F2C63E8&amp;_z=z">Privacy Policy</a></span></p>',
        cookieTimeStamp: "10",
        currencyPlacement: "BeforeNumericals",
        currencyWhitespace: false,
        currencySeparator: {
          value: {
            USD: ",",
            IDR: ",",
          },
        },
        currencyLabel: {
          value: {
            USD: "US$",
            IDR: "IDR",
          },
        },
        splashPageUpdatedDuration: "",
        splashPageUpdatedDate: "",
        faviconImageUrl:
          "/-/media/global/images/icons/favicon.ico?rev=51cce1bfcfb347b3b2fc7d304180401d",
        productErrorPictogramUrl: "",
        dateFormat: "D MMMM YYYY",
        enablePlanCardSharePostCalc: "",
        sessionTimeout: "20",
        loaderTimeout: "20",
        gAID: "GTM-MRHBSRG",
        gTMID: "GTM-MRHBSRG",
        gtmParamters: [],
        hostName: resolveAbsoluteUrl("", i18nContext),
        enableBackToTopButton: 1,
        dialogSessionTimeout: "",
        productFallbackCTA: {
          value: {
            href: "/formslist/product-enquiry-form",
            text: "Enquire",
            anchor: "",
            linkType: "form",
            title: "",
            queryString: "enquiry",
            id: "{99139D3C-9B52-4D55-8A56-472CC2AD9959}",
            gaLabel: "",
          },
        },
        brightcoveAccountID: "5650355171001",
        languageIcon: {
          key: "language-globe",
          value: "language-globe",
        },
        enableMultipleNotifications: false,
        hidePrimaryLanguage: true,
        currencyGALabelName: "ID-Dollar",
      },
      share: {
        socialApps: [
          {
            facebook: {
              header: {
                value: "Share on Facebook",
              },
              toolTip: {
                value: "",
              },
              url: {
                value: {
                  href: "https://www.facebook.com/sharer.php?u=",
                  text: "Facebook Url",
                  linktype: "external",
                  url: "https://www.facebook.com/sharer.php?u=",
                  anchor: "",
                  target: "",
                },
              },
            },
          },
          {
            twitter: {
              header: {
                value: "Share on Twitter",
              },
              toolTip: {
                value: "",
              },
              url: {
                value: {
                  href: "https://twitter.com/intent/tweet?text=",
                  text: "Twitter Url",
                  linktype: "external",
                  url: "https://twitter.com/intent/tweet?text=",
                  anchor: "",
                  target: "",
                },
              },
            },
          },
          {
            Whatsapp: {
              header: {
                value: "Share on Whatsapp",
              },
              toolTip: {
                value: "",
              },
              url: {
                value: {
                  href: "https://web.whatsapp.com/send?text=",
                  text: "Whatsapp Url",
                  linktype: "external",
                  url: "https://web.whatsapp.com/send?text=",
                  anchor: "",
                  target: "",
                },
              },
            },
          },
        ],
      },
      siteNotifications: {
        notifications: [],
      },
      dialogModals: {
        modalList: [],
      },
      disclosurePopups: {
        popupList: [],
      },
      localizedPages: languages.map((l) => ({
        [l.locale]: `/${l.languageCode}`,
      })),
      isRequestFromExternalSource: true,
      availableLanguages: languages.map((l) => l.languageCode),
      languageBroken: false,
      svgAssets: null,
    },
    translation: {
      visitFWD: footerDataInfo?.country?.preText,
    },
  };

  if (footerDataInfo?.otherSections?.image2?.iconUrl) {
    props.fields.defaultComplianceAccreditation.fields.Images.push({
      id: "035061d1-39f6-40de-8c9a-a55cae139206",
      fields: {
        link: {
          value: {},
        },
        image: {
          value: {
            src: footerDataInfo?.otherSections.image2.iconUrl,
            x1: footerDataInfo?.otherSections.image2.iconUrl,
            x2: footerDataInfo?.otherSections.image2.iconUrl,
            alt: footerDataInfo?.otherSections?.image2?.description,
          },
        },
        mobileImage: {
          value: {
            src: footerDataInfo?.otherSections.image2?.iconUrl,
            x1: footerDataInfo?.otherSections.image2?.iconUrl,
            x2: footerDataInfo?.otherSections?.image2?.iconUrl,
            alt: footerDataInfo?.otherSections?.image2?.description,
          },
        },
      },
    });
  }

  const disclosurePopupProps = disclosurePopupData.length
    ? transformDisclosurePoppupList(disclosurePopupData, i18nContext)
    : null;

  return { ...props, disclosurePopupProps };
}
